export const PATHNAMES = {
  BOOK_NOW: "/book_now",
  BOOKED: "/booked",
  CONFIRM: "/confirm",
  CONTACT: "/contact",
  DASHBOARD: "/dashboard",
  DEPARTMENTS: "/departments",
  FORGOT_PASSWORD: "/forgot_password",
  INTAKE: "/intake",
  JOURNEY: "/journey",
  JOURNEY_DEMO: "/journey_demo",
  LOCATION: "/location",
  LOGIN: "/login",
  MY_PROFILE: "/my_profile",
  NEXT_APPOINTMENT: "/next_appointment",
  OFFICE_HOURS: "/office-hours/",
  RESEND_CONFIRMATION: "/resend_confirmation",
  RESET_PASSWORD: "/reset_password",
  RESULTS: "/results",
  SCHEDULING: "/scheduling",
  SCHEDULING_BOOK: "/book_auth",
  SIGN_UP: "/signup",
  TELL_US_A_BIT: "/tell_us_a_bit_more",
  VIEW_APPOINTMENT: "/view_appointment",
  PERKS: "/perks",
  FEEDBACK: "/feedback",
  MONITORING: "/monitoring",
} as const;

export type PathnameKey = keyof typeof PATHNAMES;
export const pathKeys = Object.keys(PATHNAMES) as readonly PathnameKey[];
